import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import Welcome from "../components/welcome"
import MakeTheMost from "../components/make-the-most"
import SeparatedAgenda from "../components/separated-agenda"
import MainLayout from "../layouts/main"
import Apply from "../components/apply"
import FAQ from "../components/faq"

const SplashPage: React.FC<PageProps> = () => (
  <MainLayout
    showMainFooterContent={false}
    menuItems={[
      { label: `How to make the most of Open House`, href: `#make-the-most` },
      { label: `Agenda`, href: `#services-agenda` },
    ]}
  >
    <main id="main">
      <Welcome />
      <MakeTheMost />
      <SeparatedAgenda />
    </main>
  </MainLayout>
)

export default SplashPage

export const Head: HeadFC = () => (
  <title>Georgian College Virtual Open House</title>
)
